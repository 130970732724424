/* Mixins */
.error-component {
  position: absolute;
  top: 196px;
  right: 130px;
  background-color: white;
  padding: 80px 150px 80px 80px;
  border-radius: 10px;
}
.error-component .text-code {
  color: #414d58;
  font-size: 80px;
}
.error-component .text-desc {
  color: #8f9194;
  font-size: 20px;
}